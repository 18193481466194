
import { defineComponent, PropType, computed, onMounted, ref, getCurrentInstance } from 'vue'
import { MarketplaceIntegration } from '../../../types/marketplace'
import { useProject } from '../../../hooks/useProject'
import { marketplaceService } from '../marketplaceSerivce'

export default defineComponent({
    props: {
        integration: {
            type: Object as PropType<MarketplaceIntegration>,
            required: true,
        },
    },
    components: {},
    setup(props, {}) {
        const root = getCurrentInstance()!.proxy
        const { project } = useProject()

        const icon = computed(() => marketplaceService.getIcon(props.integration?.type))
        const statusText = computed(() => {
            if (props.integration.status === 'installed') return 'Установлено'
            if (props.integration.status === 'freezed') return 'Приостановлено!'
            if (props.integration.status === 'uninstalled') {
                if (!props.integration.price) return 'Бесплатно'
                return `От ${props.integration.price} ₽/мес.`
            }
        })
        const integrationLink = computed(
            () => `/project/${project.value.id}/marketplace/integration/${props.integration.id}`
        )
        const displayedTags = ref<string[]>(props.integration.tags)
        const tagsDiff = computed(() => props.integration.tags.length - displayedTags.value.length)
        const trimTags = () => {
            const CARD_WIDTH = 328 - 16 - 16
            const tagsContainer = root.$refs.tags as HTMLElement
            const tags = tagsContainer.querySelectorAll('.tag')

            let totalWidth = 0
            let allowedTagsCount = 0
            for (const tag of tags) {
                if (totalWidth + tag.clientWidth >= CARD_WIDTH) break
                totalWidth += tag.clientWidth
                allowedTagsCount++
            }
            displayedTags.value = props.integration.tags.slice(0, allowedTagsCount)
        }

        onMounted(() => {
            trimTags()
        })

        return { icon, displayedTags, tagsDiff, statusText, integrationLink }
    },
})
