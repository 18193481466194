
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import { useProject } from '../../../hooks/useProject'
import Breadcrumbs from '../../../components/Breadcrumbs.vue'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../components/inputs/BaseInputOutlined.vue'
import { useMarketplaceStore } from '../../../store/stores/marketplace'
import { storeToRefs } from 'pinia'
import Tags from '../../../components/inputs/Tags/Tags.vue'
import { MarketplaceIntegration } from '../../../types/marketplace'
import MarketPlaceIntegrationCard from '../components/MarketPlaceIntegrationCard.vue'
import IntegrationsNotFound from '../components/IntegrationsNotFound.vue'
import RecomendedIntegrationBanner from '../components/RecomendedIntegrationBanner.vue'
const tagFilter = (integration: MarketplaceIntegration, selectedTags: string[]) => {
    if (selectedTags.length === 0) {
        return true
    }
    if (selectedTags.includes('_installed') && integration.status !== 'installed') {
        return false
    }
    const otherSelectedTags = selectedTags.filter(tagId => tagId !== '_installed')
    return otherSelectedTags.every((tagId: string) => integration.tags.includes(tagId))
}
const searchFilter = (integration: MarketplaceIntegration, query: string) => {
    if (!query) return true
    return integration.name.toLowerCase().includes(query.toLowerCase())
}

export default defineComponent({
    components: {
        SpinnerLoader,
        Breadcrumbs,
        BaseInputOutlined,
        Tags,
        MarketPlaceIntegrationCard,
        IntegrationsNotFound,
        RecomendedIntegrationBanner,
    },
    setup(props, {}) {
        const { project } = useProject()
        const breadcrumbs = computed(() => [
            {
                text: 'ВАШИ ПРОЕКТЫ',
                to: '/projects',
            },
            {
                text: project.value.name,
                to: `/project/${project.value.id}`,
            },
            {
                text: 'МАРКЕТПЛЕЙС',
                to: '',
                disabled: true,
            },
        ])
        const searchQuery = ref('')
        const loading = ref(true)
        const selectedTags = ref([])
        const marketplaceStore = useMarketplaceStore()
        const allIntgrations = computed(() => marketplaceStore.allIntgrations(project.value.id))
        const publishedIntgrations = computed(() => marketplaceStore.publishedIntegrations(project.value.id))

        const filteredPublishedIntegrations = computed(() =>
            publishedIntgrations.value.filter(
                el => tagFilter(el, selectedTags.value) && searchFilter(el, searchQuery.value)
            )
        )

        const tags = computed(() => marketplaceStore.tags(project.value.id))
        onMounted(async () => {
            loading.value = true
            if (!allIntgrations.value.length) {
                await marketplaceStore.fetchIntegrations(project.value.id)
            }
            loading.value = false
        })

        return {
            breadcrumbs,
            loading,
            tags,
            selectedTags,
            allIntgrations,
            filteredPublishedIntegrations,
            searchQuery,
            publishedIntgrations,
        }
    },
})
