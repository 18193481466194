
import { PropType, computed, defineComponent, ref } from 'vue'
import { MarketplaceIntegration } from '../../../types/marketplace'
import Slider from '../../../components/ui/Slider/Slider.vue'
import * as _ from 'lodash'
import { useProject } from '../../../hooks/useProject'
export default defineComponent({
    props: {
        integrations: {
            type: Array as PropType<MarketplaceIntegration[]>,
            required: true,
        },
    },
    components: { Slider },
    setup(props, { emit }) {
        const { project } = useProject()
        const activeSlide = ref(0)
        const banneredIntegrations = computed(() => props.integrations.filter(el => el.banner))
        const getImage = (integration: MarketplaceIntegration) => integration.banner.img

        const boxShadow = computed(() => {
            const activeIntegration = banneredIntegrations.value[activeSlide.value]
            return `0px 20px 20px ${activeIntegration.banner.colors.shadow}`
        })
        const getIntegrationLink = (integration: MarketplaceIntegration) =>
            `/project/${project.value.id}/marketplace/integration/${integration.id}`
        const getGradient = (integration: MarketplaceIntegration) => {
            const c1 = integration.banner.colors.gradient1
            const c2 = integration.banner.colors.gradient2
            return `radial-gradient(ellipse at left top, ${c1} 25%, ${c2})`
        }

        return { banneredIntegrations, getImage, boxShadow, activeSlide, getGradient, getIntegrationLink }
    },
})
